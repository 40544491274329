import { ElementInterface, GroupElement, ScreenElement } from "store/types/project";
import { ENTITY_TYPES, PRIORITIES } from "constants/projects";
import FileResizer from "react-image-file-resizer";
import { ActiveElement } from "store/types/requirements";
import { IAttachment } from "store/types/nonFuncTech";

export const getElementData = (element: ActiveElement, scale?: number) => {
  const x1 = element.coordinates[0][0];
  const y1 = element.coordinates[0][1];
  const x2 = element.coordinates[1][0];
  const y2 = element.coordinates[1][1];
  const normalizedX = x1;
  const normalizedY = y1;
  const width = Math.abs(x1 - x2);
  const height = Math.abs(y1 - y2);
  if (scale) {
    return {
      id: `${element.id}`,
      slug: `${element.slug}`,
      x: normalizedX,
      y: normalizedY,
      width: width,
      height: height,
      fill:
        typeof element.ready_for_dev === "boolean" && !element.ready_for_dev
          ? "rgb(220,104,3, 0.2)"
          : "rgba(74, 73, 203, 0.2)",

      stroke:
        typeof element.ready_for_dev === "boolean" && !element.ready_for_dev
          ? "rgb(220,104,3)"
          : "rgb(74, 73, 203)",
      strokeWidth: 1,
      dash: [8 / scale, 4 / scale],
    };
  }
  return {
    id: `${element.id}`,
    x: normalizedX,
    y: normalizedY,
    width,
    height,
    fill:
      typeof element.ready_for_dev === "boolean" && !element.ready_for_dev
        ? "rgb(220,104,3, 0.2)"
        : "rgba(74, 73, 203, 0.2)",
    stroke:
      typeof element.ready_for_dev === "boolean" && !element.ready_for_dev
        ? "rgb(220,104,3)"
        : "rgb(74, 73, 203)",
    strokeWidth: 1,
    dash: [8, 4],
  };
};

export const resizeFile = (file: File, maxWidth: number, maxHeight: number) => {
  return new Promise<File>((resolve) => {
    FileResizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri as File);
      },
      "file",
    );
  });
};

export const getUploadScreensPayload = async (files: File[]) => {
  const formData = new FormData();

  // Resize images before upload (*limit maxRes: 2560x1140)
  const resizedImgs = await Promise.all(
    files.map(async (file) => await resizeFile(file, 2560, 1140)),
  );

  resizedImgs.forEach((el) => {
    formData.append("images", el);
  });

  return formData;
};

export const getAllElements = (item: any) => {
  let arrOfElements: any[] = [];

  if (item.item_type === ENTITY_TYPES.group) {
    arrOfElements.push(item);
    if ("screens" in item && item.screens.length) {
      const screensElements = item.screens
        .map((screen: any) => {
          return getAllElements(screen);
        })
        .flat(1);
      arrOfElements = arrOfElements.concat(screensElements);
    }
  }

  if (item.item_type === ENTITY_TYPES.screen) {
    arrOfElements.push(item);
    if ("elements" in item && item.elements.length) {
      const elements = item.elements
        .map((element: any) => {
          return getAllElements(element);
        })
        .flat(1);
      arrOfElements = arrOfElements.concat(elements);
    }
  }

  if (item.item_type === ENTITY_TYPES.element) {
    arrOfElements.push(item);
  }

  return arrOfElements;
};

export const getSidebarPayload = (values: any) => {
  const payload: any = {};

  const validationKeys = [
    "name",
    "description",
    "estimation",
    "priority",
    "acceptance_criteria",
    "status",
  ];
  const validationElementKeys = [
    "name",
    "description",
    "estimation",
    "priority",
    "elementType",
    "acceptance_criteria",
    "status",
  ];

  const validator = values.type === ENTITY_TYPES.element ? validationElementKeys : validationKeys;

  Object.keys(values).forEach((key: string) => {
    if (validator.includes(key)) {
      if (key === "elementType") {
        payload["type"] = values[key]?.trim();
      } else if (key === "estimation") {
        payload[`${key}`] = values[key]?.trim() || null;
      } else if (key === "status") {
        payload[`${key}`] = values[key];
      } else if (key === "description" || key === "acceptance_criteria") {
        // updateScreen - multipartdata, so we need to call JSON.stringify() here
        if (values.type === ENTITY_TYPES.screen) {
          payload[`${key}`] = JSON.stringify(values[key]);
        } else {
          payload[`${key}`] = values[key];
        }
      } else {
        payload[`${key}`] = values[key]?.trim();
      }
    }
  });
  return payload;
};

export const validateItem = (ids: number[], allDescendant: any[]) => {
  const arr = ids
    .map((id) => {
      return allDescendant.map((item) => {
        if (
          item.id === id &&
          (item.item_type === ENTITY_TYPES.element || item.item_type === ENTITY_TYPES.group)
        ) {
          return false;
        }
        return true;
      });
    })
    .flat();
  return arr;
};

export const reorderFeatureList = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getCorrectDraggableId = (draggableId: string) => {
  const draggableUseless = ["child-", "children-"];
  let correctDraggableId = draggableId;

  draggableUseless.forEach((el) => {
    if (draggableId.includes(el)) {
      correctDraggableId = draggableId.replace(el, "");
    }
  });
  return correctDraggableId;
};

export const createNewOrder = (
  destinationList: any[],
  sourceList: any[],
  destinationIndex: number,
  sourceIndex: number,
) => {
  const droppedItem = sourceList[sourceIndex];
  const result = [
    destinationList.slice(0, destinationIndex),
    droppedItem,
    destinationList.slice(destinationIndex, destinationList.length),
  ].flat();
  const deleted = sourceList[sourceIndex];
  const source = sourceList.filter((el) => el.slug !== deleted.slug);
  return { result, source };
};

export const getNewItems = (items: GroupElement[], newGroup: GroupElement) => {
  const currentItems = [...items];
  const filteredPreviousGroups = currentItems.map((group) => {
    const newScreens = group.screens.filter(
      (screen) => !newGroup.screens.some((newScreen) => newScreen.id == screen.id),
    );
    return { ...group, screens: newScreens };
  });

  return filteredPreviousGroups.concat(newGroup);
};
export const getDuplicatePayload = (arr: any[]) => {
  const screenIds: number[] = [];
  const groupIds: number[] = [];
  arr.map((el) =>
    el.item_type === ENTITY_TYPES.group ? groupIds.push(el.id) : screenIds.push(el.id),
  );
  return { screen_ids: screenIds, group_ids: groupIds };
};

export const replaceElementById = (arr: any[], keywordSearch: number | string, newElement: any) => {
  const parameter = typeof keywordSearch === "string" ? "slug" : "id";
  const newArray = [...arr];

  for (let i = 0; i < newArray.length; i++) {
    if (newArray[i][parameter] === keywordSearch) {
      newArray[i] = { ...newArray[i], ...newElement };
      return newArray;
    } else if (Array.isArray(newArray[i].screens)) {
      // створюємо новий об'єкт з оновленим масивом screens
      newArray[i] = {
        ...newArray[i],
        screens: replaceElementById(arr[i].screens, keywordSearch, newElement),
      };
    } else if (Array.isArray(newArray[i].elements)) {
      // створюємо новий об'єкт з оновленим масивом items
      newArray[i] = {
        ...newArray[i],
        elements: replaceElementById(arr[i].elements, keywordSearch, newElement),
      };
    }
  }
  return newArray;
};

export const getFigmaImagesPayload = (selectedImages: string[], allImages: any[]) => {
  const payload: { [key: string]: { name: string; link: string } } = {};
  selectedImages.forEach((imageId) => {
    const currentImage = allImages.find((item) => item.id === imageId);
    if (currentImage.id) {
      payload[imageId] = {
        name: currentImage.name,
        link: currentImage.image,
      };
    }
  });
  return payload;
};

export const getOptionIcon = (priority: string) => {
  switch (priority) {
    case PRIORITIES.lowest:
      return "lowestPriorityIcon";
    case PRIORITIES.low:
      return "lowPriorityIcon";
    case PRIORITIES.medium:
      return "mediumPriorityIcon";
    case PRIORITIES.high:
      return "highPriorityIcon";
    case PRIORITIES.highest:
      return "highestPriorityIcon";
    default:
      return "mediumPriorityIcon";
  }
};

export const findGroupsByIds = (groups: GroupElement[], ids: number[]) => {
  const result: GroupElement[] = [];
  for (const group of groups) {
    if (ids.includes(group.id)) {
      result.push(group);
    } else {
      for (const screen of group.screens) {
        if (ids.includes(screen.id)) {
          result.push(group);
          break;
        }
        for (const item of screen.elements) {
          if (ids.includes(item.id)) {
            result.push(group);
            break;
          }
        }
      }
    }
  }

  return result;
};

export function removeTags(input: string) {
  return input.replace(/<[^>]*>/g, "");
}

export const removeItems = (items: GroupElement[], shouldRemove: string[]): GroupElement[] => {
  const newItems = [...items];
  const removeElementsBySlug = (elements: ElementInterface[]): ElementInterface[] => {
    return elements
      .map((element) => ({ ...element })) // Глибоке копіювання можливо, якщо немає об'єктів в об'єктах
      .filter((element) => !shouldRemove.some((slug) => slug === element.slug));
  };

  const removeScreensBySlug = (screens: ScreenElement[]): ScreenElement[] => {
    return screens
      .filter((screen) => !shouldRemove.some((slug) => slug === screen.slug))
      .map((screen) => ({
        ...screen,
        elements: removeElementsBySlug(screen.elements),
      }));
  };

  const removeGroupsBySlug = (groups: GroupElement[]): GroupElement[] => {
    return groups
      .filter((group) => !shouldRemove.some((slug) => slug === group.slug))
      .map((group) => ({ ...group, screens: removeScreensBySlug(group.screens) }));
  };
  const newFilteredItems = removeGroupsBySlug(newItems);
  return newFilteredItems;
};

export const getCorrectOrder = (
  items: GroupElement[],
  allDescendant: (GroupElement | ScreenElement | ElementInterface)[],
  sourceItems: (ScreenElement | GroupElement)[],
  destinationOrder: number,
) => {
  let newItems = allDescendant;
  const reversedSourceItems = sourceItems.reverse();
  const firstItem = sourceItems[0];
  const destinationIndex = allDescendant.findIndex((el) => el.order === destinationOrder);
  if (firstItem.order > destinationOrder) {
    for (const i in sourceItems) {
      const currentItem = sourceItems[i];
      const itemIndex = allDescendant.findIndex((el) => el.order === currentItem.order);

      const slicedItems = newItems.slice(destinationIndex - 1, itemIndex);
      newItems = allDescendant.map((element) => {
        if (slicedItems.some((item) => item.id === element.id)) {
          return { ...element, order: element.order + 1 };
        }
        return element;
      });
    }
  } else {
    for (const i in reversedSourceItems) {
      const currentItem = sourceItems[i];
      const itemIndex = allDescendant.findIndex((el) => el.order === currentItem.order);
      const slicedItems = newItems.slice(itemIndex, destinationIndex + 1);

      newItems = allDescendant.map((element) => {
        if (slicedItems.some((item) => item.id === element.id)) {
          return { ...element, order: element.order - 1 };
        }
        return element;
      });
    }
  }
  const newItem = { ...firstItem, order: destinationOrder };
  const index = allDescendant.findIndex((item) => item.id === newItem.id);
  if (index !== -1) {
    newItems[index] = newItem;
  }
  const newGroups = updateItems(items, newItems);
  return newGroups;
};

function updateItems(
  items: GroupElement[],
  allDescendant: (GroupElement | ScreenElement | ElementInterface)[],
) {
  function findAndUpdate(item: GroupElement | ScreenElement | ElementInterface) {
    const updatedItem = allDescendant.find((updated) => updated.id === item.id);
    return updatedItem ? { ...updatedItem } : item;
  }

  function updateElements(elements: ElementInterface[]) {
    return elements.map((element) => findAndUpdate(element));
  }

  function updateScreens(screens: ScreenElement[]) {
    return screens.map((screen) => ({
      ...findAndUpdate(screen),
      elements: updateElements(screen.elements),
    }));
  }

  function updateGroups(groups: GroupElement[]) {
    return groups.map((group) => ({
      ...findAndUpdate(group),
      screens: updateScreens(group.screens),
    }));
  }

  return updateGroups(items);
}

export const bulkMove = (
  selectedItems: number[],
  allItems: GroupElement[],
  allDescendant: (GroupElement | ScreenElement | ElementInterface)[],
  groupDestination: number,
) => {
  const currentGroup = allItems.find((group) => group.id === groupDestination);
  const selectedScreens = allDescendant.filter((item) =>
    selectedItems.includes(item.id),
  ) as ScreenElement[];

  const newScreens = removeDuplicatesFromGroupScreens(
    currentGroup?.screens.concat(selectedScreens),
  );
  const newCurrentGroup = {
    ...currentGroup,
    screens: newScreens,
  };
  const allFilteredItems = allItems.map((group) => {
    if (group.id !== newCurrentGroup.id) {
      const newScreens = group.screens.filter((screen) => !selectedItems.includes(screen.id));
      return { ...group, screens: newScreens };
    } else {
      return newCurrentGroup;
    }
  });
  return allFilteredItems;
};

export const formatTextWithNewlines = (text: string) => {
  const formattedText = text;
  if (formattedText.includes("\n")) {
    const lines = formattedText.split("\n");
    const paragraphs = lines.map((line) => {
      if (line) {
        return {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: line,
            },
          ],
        };
      }
      return {
        type: "paragraph",
      };
    });
    const jsonFormatted = {
      type: "doc",
      content: paragraphs,
    };
    return jsonFormatted;
  } else {
    return formattedText;
  }
};

export const removeDuplicatesFromGroupScreens = (arr?: any[]) => {
  const uniqueObjects: any = new Map();
  if (arr) {
    for (let i = arr.length - 1; i >= 0; i--) {
      const obj = arr[i];
      if (!uniqueObjects.has(obj.id)) {
        uniqueObjects.set(obj.id, obj);
      }
    }

    const uniqueArray = Array.from(uniqueObjects.values());

    return uniqueArray.reverse();
  }
  return [];
};

export const convertToHoursMinutes = (value: number) => {
  if (typeof value !== "number") {
    return "invalid";
  }
  if (value < 0) {
    return value;
  }

  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);

  let result = "";

  if (hours > 0) {
    result += `${hours}h`;
  }

  if (minutes > 0) {
    result += (hours > 0 ? " " : "") + `${minutes}m`;
  }

  if (result === "") {
    result = "0m";
  }

  return result;
};

export const transformArrayToObject = (
  arr: {
    layout: string;
    resolution: string;
    notes: string;
    order: number;
  }[],
) => {
  return arr.reduce((acc, item) => {
    acc[item.layout] = { resolution: item.resolution, notes: item.notes };
    return acc;
  }, {});
};
export function checkContentField(obj: any) {
  if (!obj || !obj.content || !Array.isArray(obj.content)) {
    return false;
  }

  return obj.content.every((item) => "content" in item);
}

export const getAttachmentsArray = (attachments: IAttachment[]) => {
  const newAttachments = attachments.map((item) => {
    const splittedName = item.file.split("/");
    const name = splittedName[splittedName.length - 1];
    return { file: item.file, name, id: item.id };
  });
  return newAttachments;
};
