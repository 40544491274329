import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequirements, postRequirements } from "api/fetchRequests/nonFuncTech";
import { getEmptyNonTech } from "constants/projects";
import { errorNotify } from "hooks/useNotifications";
import { NonFuncTechProps } from "store/types/nonFuncTech";
import { getAttachmentsArray } from "utils/projectUtils.";

const initialState: NonFuncTechProps = {
  isLoading: true,
  isButtonActive: [],
  attachments: [],
  general_information: {
    project_details: "",
  },
  platforms: [],
  responsive_layouts: {
    desktop: { resolution: "", notes: "", order: 1 },
    tablet: { resolution: "", notes: "", order: 2 },
    mobile: { resolution: "", notes: "", order: 3 },
  },
  availability_accessibility: {
    browser: [],
    countries: [],
    languages: [],
    accessibility: [],
  },
};

export const fetchNonFuncTech = createAsyncThunk(
  "fetchNonFuncTech",
  async ({ id }: { id: number }) => {
    try {
      const res = await getRequirements(id);
      const attachments = getAttachmentsArray(res.data.attachments);

      return { data: { ...res.data, attachments } };
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 404) {
        await postRequirements(getEmptyNonTech(id));
      } else {
        errorNotify(error);
      }
    }
  },
);

export const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState,
  reducers: {
    setProjectDetails: (state, { payload }) => {
      state.general_information.project_details = payload;
    },
    setAttachments: (state, { payload }) => {
      const newAttachments = getAttachmentsArray(payload);
      state.attachments = newAttachments;
    },
    setPlatforms: (state, { payload }) => {
      state.platforms = payload;
    },
    setResponsiveLayouts: (state, { payload }) => {
      state.responsive_layouts = payload;
    },
    setAvailabilityAccessibility: (state, { payload }) => {
      state.availability_accessibility = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNonFuncTech.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNonFuncTech.fulfilled, (state, { payload }) => {
        return { ...state, ...payload?.data, isLoading: false };
      })
      .addCase(fetchNonFuncTech.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const {
  setProjectDetails,
  setPlatforms,
  setResponsiveLayouts,
  setAvailabilityAccessibility,
  setAttachments,
} = projectDetailsSlice.actions;

export default projectDetailsSlice.reducer;
